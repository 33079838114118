<template>
  <UiSidePanel v-model="vModel" :size="size">
    <template #default>
      <div class="flex h-full flex-row">
        <div class="flex h-full flex-col" :class="$slots.sidePanel ? 'w-7/12' : 'w-full'">
          <h4
            class="px-8 pb-1 pt-10"
            :class="{ 'z-[1] border-b border-solid border-black-10 shadow': showTopOverflowBorder }"
          >
            {{ title }}
          </h4>
          <div ref="content" class="flex h-full flex-col gap-8 overflow-y-auto px-8 pt-3" @scroll="checkScroll">
            {{ description }}
            <slot />
          </div>
          <div
            class="flex flex-row gap-4 p-8"
            :class="[
              secondaryButtonText ? 'justify-end' : 'justify-center',
              {
                'z-[1] border-t border-solid border-black-10 shadow-up': showBottomOverflowBorder,
              },
            ]"
          >
            <UiButtonBase v-if="secondaryButtonText" id="secondary_button" type="secondary" size="big" @click="cancel">
              {{ secondaryButtonText }}
            </UiButtonBase>
            <UiButtonBase
              v-if="primaryButtonText"
              id="primary_button"
              :disabled="disabled"
              size="big"
              @click="confirm"
              >{{ primaryButtonText }}</UiButtonBase
            >
          </div>
        </div>
        <div v-if="$slots.sidePanel" class="relative w-5/12 border-l border-solid border-black-10">
          <slot name="sidePanel"></slot>
        </div>
      </div>
    </template>
  </UiSidePanel>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'confirm', 'cancel'])

type Props = {
  modelValue: boolean
  size?: 'small' | 'medium' | 'big' | 'large'
  title: string
  description?: string
  primaryButtonText: string
  secondaryButtonText?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'small',
  secondaryButtonText: '',
  description: '',
})

const vModel = useVModel(props, 'modelValue', emits)

let contentOverflows = ref(false)
const showTopOverflowBorder = ref(false)
const showBottomOverflowBorder = ref(false)
const content = ref<HTMLElement>()

onMounted(() => {
  contentOverflows = computed(() => Number(content?.value?.scrollHeight) > Number(content?.value?.offsetHeight))
  setTimeout(() => {
    checkScroll()
  })
})

const checkScroll = () => {
  if (contentOverflows.value) showTopOverflowBorder.value = Number(content.value?.scrollTop) > 0
  showBottomOverflowBorder.value =
    Math.abs(
      Number(content.value?.scrollHeight) - Number(content.value?.scrollTop) - Number(content.value?.clientHeight)
    ) > 1
}

const cancel = () => {
  emits('cancel')
  emits('update:modelValue', false)
}

const confirm = () => {
  emits('confirm')
}
</script>

<style scoped></style>
